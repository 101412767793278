<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<!-- Created at: 2021-04-30 22:52 -->
<template>
  <div class="container mt-3">
    <div class="row">
      <div class="col-md-6">
        <Login @logued="loguedHandler"></Login>
      </div>
    </div>
  </div>
</template>
<script>
import Login from "src/lt/auth-module/Login.vue";
import redirectAction from "src/lt/auth-module/redirectAction.js";
export default {
  components: {
    Login
  },

  // directives
  // filters

  props: {
    //
  },

  data: () => ({
    //
  }),

  computed: {
    //
  },

  // watch: {},

  mounted() {
    //
  },

  methods: {
    loguedHandler() {
      redirectAction(this);
    }
  }
};
</script>

<style scoped></style>
